/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://6mvo2mhl3vduxnyjwxsoo2aicu.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yy7im73dbfg6vhzz5kwpfhztzu",
    "aws_cognito_identity_pool_id": "eu-central-1:7a6390b6-79ec-44ae-86b3-be28c0a2284a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_6i2lpxwja",
    "aws_user_pools_web_client_id": "o7acdsah4ml4v13pno0s14tmb",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "predictions": {
        "convert": {
            "transcription": {
                "region": "eu-central-1",
                "proxy": false,
                "defaults": {
                    "language": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
